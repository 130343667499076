.export-card {
  margin-top: 32px;
}

.export-card__button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 6px;
}
