.profile-page__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  margin: 50px auto;
}

.profile-page__left-column {
  width: 100%;
}

.profile-page__right-column {
  margin-top: 40px;
  width: 100%;
}

@media (min-width: 768px) {
  .profile-page__container {
    flex-direction: row;
  }

  .profile-page__left-column {
    width: 400px;
  }

  .profile-page__right-column {
    margin-top: 0;
    width: calc(100% - 540px);
  }
}

.profile-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(209, 209, 209, 0.75);
  position: relative;
}

.profile-card__image {
  margin-top: 2rem;
  width: 140px;
  border-radius: 70px;
}

.profile-card__info {
  display: flex;
}

.profile-card__name {
  margin-top: 2em;
  color: grey;
  font-size: 1.5em;
  width: 100%;
  display: flex;
  justify-content: center;
  line-height: 1em;
}

.profile-card__email {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: black;
  line-height: 1em;
}

.profile-card__footer {
  height: 2em;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
