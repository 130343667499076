.checkbox-group {
  display: flex;
  flex-direction: column;
}

.checkbox-group + .checkbox-group {
  margin-top: 0;
}

.checkbox-group .checkbox-group-label {
  color: #3A3A3A;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
  padding: 8px 20px;
  margin: 10px -20px 0;
  appearance: none;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.checkbox-group .checkbox-group-label img {
  position: relative;
  top: -2px;
}
.checkbox-group--open .checkbox-group-label img {
  top: 2px;
}

.checkbox-group .checkbox-group-option {
  display: none;
}

.checkbox-group-options::-webkit-scrollbar {
  width: 5px; 
}

.checkbox-group-options::-webkit-scrollbar-button {
  display: none; 
}

.checkbox-group-options::-webkit-scrollbar-track {
  background: transparent; 
}

.checkbox-group-options::-webkit-scrollbar-thumb {
  background: #cccccc; 
  border-radius: 10px; 
}

.checkbox-group-options::-webkit-scrollbar-thumb:hover {
  background: #888888;
}

.checkbox-group--open .checkbox-group-option,
.checkbox-group .checkbox-group-option--checked {
  display: block;
}

.checkbox-group .checkbox-group-label:hover {
  background-color: rgba(217, 217, 217, 0.25);
}

.checkbox-group .checkbox-group__show-more {
  cursor: pointer;
  appearance: none;
  border: 0;
  background-color: transparent;
  color: #B0B0B0;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -20px;
  padding: 8px 20px 8px 46px;
}

.checkbox-group .checkbox-group__show-more:hover {
  background-color: rgba(217, 217, 217, 0.25);
}

.checkbox-group .checkbox-group__show-more img {
  margin-left: 10px;
}

.checkbox-group-options {
  max-height: 300px;
  overflow-y: auto;
  padding: 0 20px;
  margin: 0 -20px;
}

.checkbox-group-option {
  cursor: pointer;
  padding: 5px 0;
}

.checkbox-group-option input {
  display: none;
}

.checkbox-group-option p {
  position: relative;
  margin: 0;
  padding-left: 26px;
}

.checkbox-group-option p::before {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #D9D9D9;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  background-position: 2px 0;
  background-repeat: no-repeat;
}

.checkbox-group-option:hover p::before {
  border-color: #ED1C24;
}

.checkbox-group-option input:checked ~ p::before {
  background-color: #ED1C24;
  background-image: url(../../../../../assets/icon--check--white.svg);
  border-width: 0;
}

.checkbox-group-option:hover input:checked ~ p::before {
  background-color: #D9D9D9;
}