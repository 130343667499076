.search-results--projects {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.search-results--projects .title {
  font-size: 16px;
  color: #3A3A3A;
  display: flex;
  align-items: center;
}

.search-results--projects .title button {
  margin-right: 8px;
}

.search-results--project {
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  padding: 12px 16px 12px 32px;
  margin-top: 1em;
  border: 2px solid #BBBBBB;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  color: #3A3A3A;
}

.search-results--project:before {
  box-sizing: border-box;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 100%;
  background-color: #BBBBBB;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.search-results--project:nth-child(1) {
  margin-top: 0;
}

.search-results--project__match {
  font-size: 16px;
  color: #BBBBBB;
  font-weight: 700;
}

.search-results--partner-group .search-results--buttons__default:nth-last-child(1) .action-button {
  margin-right: 0;
}

.search-results--partner-totals {
  display: flex;
}

.search-results--partner-totals div {
  background-color: rgb(209, 209, 209, 0.2);
  font-size: 12px;
  padding: 0.5em;
  color: rgb(102, 102, 102);
  margin-top: 0.5rem;
}

.search-results--partner-totals p {
  margin: 0;
}

.search-results--partner-totals strong {
  text-transform: capitalize;
}

.search-results--partner-totals .numeric {
  text-align: right;
  font-variant-numeric: lining-nums;
}

.search-results--project:active {
  outline: none;
}

.search-results--project__title {
  text-align: left;
  width: 80%;
}

.search-results__showing_call {
  padding: 1em;
}

.partner-dialog {
  display: flex;
}

.partner-dialog__left-column {
  width: 60%;
  padding: 1em 1em 1em 0;
}

.partner-dialog__right-column {
  background-color: rgb(244, 244, 244);
  width: 40%;
  padding: 1em;
}

.partner-dialog__action-buttons {
  margin-top: 3em;
  display: flex;
}
