.content-dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(49, 49, 49, 0.62);
}

.content-dialog {
  box-sizing: border-box;
  position: fixed;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 32px);
  max-width: 1700px;
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  z-index: 10;
  background-color: white;
  border: 1px solid #ABABAB;
  border-radius: 6px;
  padding: 24px 0 0 24px;
}

@media (min-width: 768px) {
  .content-dialog {
    width: calc(100vw - 80px);
  }
}

/*
  .---------.
  |XXXXXXXXX|
  |         |
  |         |
  '---------'
*/
.content-dialog h2 {
  font-size: 21px;
  font-weight: 600;
  color: #545454;
  margin-top: 0;
  margin-right: 48px;
}

.content-dialog a {
  color: rgb(237, 28, 36);
}

.content-dialog .close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #707070;
  border: 0;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.2s ease-out;
}

.content-dialog .close svg {
  width: 32px;
  height: 32px;
}

.content-dialog .close:hover {
  color: black;
}

.content-dialog__content {
  overflow-y: auto;
  font-size: 13px;
  font-family: 'Open sans', sans-serif;
}

.content-dialog__content-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 1024px) {
  .content-dialog__content-wrapper {
    flex-direction: row;
  }
}

.content-dialog table td {
  padding: 2px;
}

/*
  .---------.
  |       XX|
  |       XX|
  |       XX|
  '---------'
*/
.content-dialog__aside {
  position: relative;
  width: 100%;
}

@media (min-width: 1024px) {
  .content-dialog__aside {
    width: 400px;
    min-width: 400px;
  }
}

.content-dialog__aside__wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #505050;
  border-radius: 12px;
  padding: 16px 24px;
  color: white;
}

.content-dialog__aside a,
.content-dialog__aside em {
  color: rgb(218 137 140);
}

.content-dialog__aside .external-links {
  margin-top: 8px;
}

.content-dialog__aside .action-button {
  text-decoration: none;
  height: auto;
  color: white;
  background-color: rgb(237, 28, 36);
  border-color: rgb(237, 28, 36);
  padding: 6px 16px 5px;
}

.content-dialog__aside .action-button:hover {
  border-color: rgb(237, 28, 36);
  background-color: rgb(213, 26, 32);
}

/*
  .---------.
  |XXXXXX   |
  |XXXXXX   |
  |XXXXXX   |
  '---------'
*/
.content-dialog__content {
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px 0 0;
}

.content-dialog__content table {
  border: 2px solid #DEDEDE;
  border-collapse: separate;
  border-radius: 29px;
  border-spacing: 0;
}

.content-dialog__content table th,
.content-dialog__content table td {
  border-top: 2px solid #DEDEDE;
  border-left: 2px solid #DEDEDE;
  padding: 12px 20px;
}

.content-dialog__content table tbody:nth-child(1) tr:nth-child(1) th,
.content-dialog__content table tbody:nth-child(1) tr:nth-child(1) td {
  border-top: 0;
}

.content-dialog__content table th:nth-child(1),
.content-dialog__content table td:nth-child(1) {
  border-left: 0;
}

.content-dialog__content table th {
  font-size: 20px;
  font-weight: 700;
  color: #808080;
}

.content-dialog__content table td {
  font-size: 18px;
  line-height: 24px;
  color: #707070;
}


.content-dialog__content h2 {
  margin-top: 0;
}
.content-dialog__content p {
  line-height: 1.4em;
}
.content-dialog__content ul {
  line-height: 1.4em;
}

.content-dialog__content ul li {
  margin-bottom: 0.5rem;
}

.content-dialog__aside .topic-information li {
  margin-bottom: 16px;
}

.content-dialog__content .highlighted-budget {
  background: #90ee90;
}
.content-dialog__content .highlighted-trl {
  background: #80bfff;
}

.content-dialog__content .highlighted-query-term {
  background: #ffb380;
}

.content-dialog__content .highlighted-match-sentence {
  background: #fdeb7a;
}