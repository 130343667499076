footer {
  border-top: 1px solid rgb(209, 209, 209, 0.5);
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 0.80em;
  color: rgb(150, 150, 150);
}

footer a {
  color: #ED1C24;
}

footer a:visited {
  color: rgb(150, 150, 150);
}

.footer--legal {
  display: flex;
  justify-content: space-between;
}

.footer--legal > p:first-child {
  margin-right: 2em;
}

@media (max-width: 600px) {
  .footer--legal {
    width: 100%;
  }
}
