.saved-topics {
  margin: 0 40px 0 0;
}

.saved-topics__topic {
  margin: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid rgb(209, 209, 209, 0.5);
  padding: 8px 0;
}

.saved-topics__topic-title {
  font-family: 'Open sans', sans-serif;
  font-size: 13px;
  max-width: 80%;
  color: #3A3A3A;
  margin: 0;
}

.saved-topics__topic-buttons {
  display: flex;
  flex-direction: column;
}

.saved-topics__topic-buttons button {
  text-transform: uppercase;
  font-size: 14px;
  color: white;
  background-color: #D8D8D8;
  border: 0;
  border-radius: 4px;
  margin-top: 4px;
  padding: 3px 8px;
  cursor: pointer;
}

.saved-topics__topic-buttons button:nth-child(1) {
  margin-top: 0;
}

.saved-topics__topic-buttons button.open {
  background-color: #AFCED1;
}