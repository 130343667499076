.project-dialog {
  display: flex;
}

.project-dialog__left-column {
  width: 60%;
  padding: 1em 1em 1em 0;
}

.project-dialog__left-column h2,
.project-dialog__right-column h3 {
  margin-top: 0;
}

.project-dialog__right-column {
  background-color: rgb(244, 244, 244);
  width: 40%;
  padding: 1em;
  line-height: 1.5em;
}

.project-dialog__right-column > ul {
  list-style: none;
  padding-left: 0;
}

.project-dialog__right-column > ul li {
  margin-bottom: 0.5rem;
}

.project-dialog__right-column > ul > li {
  margin-bottom: 1rem;
}

.project-dialog__right-column strong {
  display: block;
}

.project-dialog__action-buttons {
  margin-top: 3em;
  display: flex;
}

.project-information p {
  margin: 0;
}

.project-information .value {
  margin-bottom: 16px;
  text-transform: uppercase;
}