.search-page {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.aside {
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-bottom: 32px;
}

.main {
    margin-top: 15px;
    max-width: 1420px;
    margin-left: 16px;
    margin-right: 16px;
    flex-grow: 1;
}

.search-results--container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  grid-column: 3/11;
  position: relative;
}

.search-results--content {
    width: 100%;
}


.search-results--row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(209, 209, 209, 0.5);
  padding: 20px 0;
}

.search-results--row:nth-child(1) {
  border-top: 0;
}

.filter-sticky-container {
  height: 100%;
}

@media (max-width: 1440px) {
  .search-results--container {
    grid-column: 2/12;
  }
}

@media (max-width: 600px) {
  .search-results--container {
    grid-column: 1/13;
  }
}

@media (min-width: 768px) {
    .aside {
        width: 240px;
        min-width: 240px;
        margin-left: 24px;
        margin-bottom: 0;
    }

    .main {
        margin-left: 32px;
        margin-right: 32px;
    }
}

@media (max-width: 1080px) {
    .search-results--row {
        flex-wrap: wrap;
    }
}

.pagination-button {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.pagination {
  margin-left: 45%;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  width: 100px;
}

.no-data {
  display: flex;
  justify-content: center;
  margin-top: 20%;
}

.export-excel {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.spinner-container {
  margin-top: 20%;
}

.dropdown__item {
  display: flex;
  justify-content: space-between;
  transition-duration: 0.5s;
  padding: 8px 8px;
  text-transform: capitalize;
  transition: all 0.2s ease 0s;
  border-left: 1px solid rgb(209, 209, 209, 0);
}

.dropdown__item:hover {
  cursor: pointer;
  background-color: rgb(245, 245, 245);
}

.export-all {
  display: flex;
}

.export-icon {
  width: 15px;
  height: 15px;
  padding-top: 8px;
}

.upload-funding-data {
  display: flex;
  justify-content: flex-end;
  padding: 5px 11%;
}

.delete-funding-data {
  display: flex;
  justify-content: flex-end;
  padding: 5px 11%;
}

.message-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  max-width: 90%; 
  z-index: 1000;
}

.custom-delete-checkbox {
  margin-right: 0;
}

.manipulate-funding-container {
  display: flex;
  justify-content: flex-end;
  padding: 5px 5%;
  gap: 10px;
}

.manipulate-select-container {
  display: flex;
  justify-content: flex-end;
  padding: 5px 5%;
  gap: 25px;
}

.model-content-container {
  display: flex;
  flex-direction: column;
  width: 250px;
 }

.upload-download-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.add-select-programe-container {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}