.accordion {
  margin-bottom: 1rem;
}

.accordion__toggle {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
  border: 0;
  font-size: 1.375rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: rgb(47, 48, 49);
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s ease-out;
}

.accordion__toggle:hover {
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.15);
}

.accordion__toggle i {
  margin-right: 1rem;
}

.accordion__content {
  margin-top: 1rem;
  display: none;
}

.accordion__content--visible {
  display: block;
}
