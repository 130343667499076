.search-results--container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  grid-column: 3/11;
  position: relative;
}

@media (max-width: 1440px) {
  .search-results--container {
    grid-column: 2/12;
  }
}

@media (max-width: 600px) {
  .search-results--container {
    grid-column: 1/13;
  }
}

.search-results--content {
  width: 100%;
}

.search-results--row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(209, 209, 209, 0.5);
  padding: 20px 0;
}

.search-results--row:nth-child(1) {
  border-top: 0;
}

.search-results--result-name {
  color: #3a3a3a;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.search-results--result-description {
  font-family: 'Open sans', sans-serif;
  font-size: 13px;
  line-height: 1.2em;
  color: #3a3a3a;
  text-align: justify;
  position: relative;
}

.search-results--first-column {
  padding-right: 40px;
  position: relative;
  flex-grow: 1;
}

.search-results--subscribe {
  font-size: 10px;
  display: flex;
  height: 205px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.search-results--subscribe a {
  color: #ed1c24;
}

.search-results--expired {
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.search-results--expired .subscribe {
  margin-left: 3px;
  color: #ed1c24;
}

@media (max-width: 1080px) {
  .search-results--row {
    flex-wrap: wrap;
  }
  .search-results--first-column {
    width: 100%;
    padding-right: 0;
  }

  .search-results--second-column {
    width: 100%;
    margin-top: 16px;
  }

  .search-results--second-column-national {
    width: 100%;
    margin-top: 16px;
  }
}

.search-results--buttons {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.search-results--buttons--reverse-row {
  flex-direction: row-reverse;
}

.search-results--buttons__action button:nth-last-child(1) {
  margin-right: 0;
}

.search-results--second-column {
  min-width: 304px;
  width: 304px;
}

.search-results--second-column-national {
  min-width: 220px;
  width: 220px;
}

.search-results--icon-area {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5em;
}

.search-results--label-area {
  padding-top: 20px;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 400px;
}

.ms-DetailsRow-cell[data-automation-key='key'],
.ms-DetailsRow-cell[data-automation-key='id'],
.ms-DetailsHeader-cell[data-item-key='key'],
.ms-DetailsHeader-cell[data-item-key='id'] {
  display: none;
}

.ms-DetailsRow-cell[data-automation-key='title'] button {
  color: rgb(47, 48, 49);
}

.search-results__details {
  display: flex;
  justify-content: flex-start;
}

.search-results__detail {
  box-sizing: border-box;
  width: 96px; /*calc((100% - 16px) / 3);*/
  height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #e1e1e1;
  border-radius: 8px;
  color: #ed1c24;
  margin-left: 8px;
}

.search-results__detail:nth-child(1) {
  margin-left: 0;
}

.search-results__detail--highlight {
  border-color: #ed1c24;
  color: #3a3a3a;
}

.search-results__detail__value {
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
}

.search-results__detail__label {
  text-align: center;
  margin-top: 8px;
  font-size: 18px;
}

.search-results__detail__url {
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  color: #ed1c24;
}

.search-results__detail--started .search-results__detail__value {
  font-size: 16px;
  line-height: 20px;
}

.search-results__detail--deadline .search-results__detail__value {
  font-size: 16px;
  line-height: 20px;
}

.search-results__detail--deadline .search-results__detail__value {
  font-size: 16px;
  line-height: 20px;
}

.search-results__detail--opening .search-results__detail__value {
  font-size: 16px;
  line-height: 20px;
}
.search-results__detail--budget .search-results__detail__label {
  font-size: 24px;
  line-height: 24px;
  margin: 0;
}

.topic-organizations,
.topic-tags,
.topic-keywords {
  font-size: 13px;
}

.topic__info .ms-CollapsibleSection > button span {
  font-size: 13px;
}