.filter-sticky-container {
  height: 100%;
}
.result-filter__container {
  position: relative;
  top: 0;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  margin-top: 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 2px solid #E9E9E9;
  border-radius: 20px;
  padding: 20px;
}

@media (min-width: 768px) {
  .result-filter__container {
    position: sticky;
    top: 35px;
    margin-top: 35px;
  }
}

.result-filter {
  width: 100%;
}

.result-filter__filter-group {
  display: flex;
  flex-direction: column;
}

.filter-group-label {
  margin: 0;
  font-weight: 600;
}

.filter-group-label + .radio-group {
  margin-top: 16px;
}

.result-filter__filter-item {
  width: 100%;
  padding: 1em 0 0;
}

.result-filter__group-actions {

}

.result-filter__clear-button {
  padding: 8px;
  cursor: pointer;
  background-color: white;
  color: rgb(147, 147, 147);
  border: 1px solid rgb(147, 147, 147);
  display: block;
  border-radius: 8px;
  width: 100%;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.radio-group + .filter-group-label {
  margin-top: 20px;
}