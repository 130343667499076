.intro {
  max-width: calc(100% - 40px);
  width: 1682px;
  margin: 0 auto;
}

.intro__content {
  position: relative;
  padding: 56px;
  border: 1px solid #B5B5B5;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1023px) {
  .intro__content {
    padding: 40px;
    flex-direction: column;
  }
}

.intro__content__item {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #545454;
  min-width: 33%;
  padding: 0 16px;
}

.intro__content__item.filtering {
  min-width: auto;
}

@media (max-width: 1023px) {
  .intro__content__item {
    flex-direction: row;
  }
}

@media (max-width: 1439px) and (min-width: 1024px) {
  .intro__content__item {
    flex-direction: column;
    min-width: auto;
  }
}

@media (min-width: 1440px) {
  .intro__content__item {
    flex-direction: row;
  }
}

.intro__content__item:nth-child(1) {
  margin-left: 0;
}

.intro__content__item p {
  margin-top: 0;
}

.intro__content__item img {
  margin-right: 24px;
  max-width: 210px;
}

@media (max-width: 1023px) {
  .intro__content__item img {
    max-height: 128px;
  }
}

@media (max-width: 1440px) {
  .intro__content__item img {
    margin-right: 0;
    margin-bottom: 40px;
  }
}

/* SEARCH */
.intro__content__item.search ul {
  list-style-type: none;
  padding: 0;
}

.intro__content__item.search li:before {
  content: "–";
  margin-right: 8px;
}


/* FILTERING */
.intro__content__item.filtering .filtering__inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro__content__item.filtering p {
  margin: 0;
}

.intro__content__item.filtering img {
  margin-left: 8px;
  margin-right: 0;
  margin-bottom: 0;
  max-height: 100%;
}

.intro__content__item.filtering ol {
  padding: 0;
  list-style: none;
  counter-reset: intro-filtering;
}

.intro__content__item.filtering li {
  display: flex;
  align-items: center;
  counter-increment: intro-filtering;
  white-space: nowrap;
}

.intro__content__item.filtering li:nth-child(2) {
  margin: 58px 0;
}

.intro__content__item.filtering li:before {
  box-sizing: border-box;
  min-width: 40px;
  min-height: 40px;
  font-size: 26px;
  border: 2px solid #C1C1C0;
  border-radius: 20px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  content: counter(intro-filtering);
}

/* DON'T SHOW AGAIN - CONTINUE */
.intro__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 56px;
  font-size: 20px;
  font-weight: 600;
}

.intro__dont-show-again input {
  display: none;
}

.intro__dont-show-again label {
  display: flex;
  align-items: center;
  color: #545454;
  cursor: pointer;
  font-size: 18px;
}

.intro__dont-show-again .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
  border: 3px solid #AFCED1;
}

.intro__actions button {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #AFCED1;
  color: white;
  border: 0;
  border-radius: 22px;
  font-size: 18px;
  font-weight: 600;
  padding: 0 18px;
  margin-left: 56px;
  cursor: pointer;
}