.search-page {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.aside {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  margin-left: 16px;
  margin-bottom: 32px;
}

.main {
  max-width: 1420px;
  margin-left: 16px;
  margin-right: 16px;
  flex-grow: 1;
}

/* login container */
.login-container {
  padding: 2px;
  text-align: center;
}

.login-container h1 {
  font-size: 36px;
  color: #ed1c24;
  margin: 0;
}

.login-container h2 {
  font-size: 28px;
  margin: 24px 0 56px;
}

.login-container .actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container .actions button {
  background-color: white;
  border: 1px solid #212121;
  border-radius: 80px;
  font-size: 14px;
  font-weight: 600;
  color: #4d4d4d;
  padding: 12px 52px;
  margin-left: 16px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.login-container .actions button:hover {
  border-color: #ed1c24;
}

.login-container .actions button:nth-child(1) {
  margin-left: 0;
}

/* mobile view at 376px min */
@media (min-width: 376px) {
  .login-container {
    padding: 5px;
  }

  .login-container h1 {
    font-size: 42px;
  }

  .login-container h2 {
    font-size: 30px;
  }

  .login-container .actions button {
    font-size: 16px;
    padding: 12px 52px;
    margin-left: 16px;
  }
}

/* mobile view at 600px min */
@media (min-width: 600px) {
  .login-container {
    padding: 32px;
  }

  .login-container h1 {
    font-size: 48px;
  }

  .login-container h2 {
    font-size: 32px;
  }

  .login-container .actions button {
    font-size: 20px;
    padding: 14px 54px;
    margin-left: 36px;
  }
}

/* mobile view at 768px min */
@media (min-width: 768px) {
  .aside {
    width: 240px;
    min-width: 240px;
    margin-left: 24px;
    margin-bottom: 0;
  }

  .main {
    margin-left: 32px;
    margin-right: 32px;
  }

  .login-container {
    padding: 48px;
  }

  .login-container h1 {
    font-size: 52px;
  }

  .login-container h2 {
    font-size: 36px;
  }

  .login-container .actions button {
    font-size: 26px;
    padding: 16px 56px;
    margin-left: 46px;
  }
}
