.dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1em;
  left: 0;
  display: none;
}

.dropdown__header {
  position: relative;
}

.dropdown__header--title {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
}

.dropdown__header--title:focus {
  outline: none;
}

.dropdown__header--arrow {
  margin-left: 1em;
  color: rgb(237, 28, 36);
}

.dropdown__list {
  background-color: white;
  margin: 0;
  padding: 0;
  border-top: none;
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
}

.dropdown__label {
  color: #3A3A3A;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
  padding: 8px 0;
}

.dropdown__value {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown__item {
  display: flex;
  justify-content: space-between;
  transition-duration: 0.5s;
  padding: 8px 8px;
  text-transform: capitalize;
  transition: all 0.2s ease 0s;
  border-left: 1px solid rgb(209, 209, 209, 0);
}

.dropdown__item:hover {
  cursor: pointer;
  background-color: rgb(245, 245, 245);
}

.dropdown__item--icon {
  display: flex;
  align-items: center;
}
