.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-group-option {
  cursor: pointer;
  padding: 5px 0;
}

.radio-group-option input {
  display: none;
}

.radio-group-option p {
  position: relative;
  margin: 0;
  padding-left: 26px;
}

.radio-group-option p::before {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #D9D9D9;
  border-radius: 8px;
  transition: all 0.2s ease-out;
}

.radio-group-option:hover p::before {
  border-color: #ED1C24;
}

.radio-group-option input:checked ~ p::before {
  border-color: #ED1C24;
  border-width: 5px;
}