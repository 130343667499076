.action-button {
  box-sizing: border-box;
  height: 30px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid #B4B4B4;
  color: #3A3A3A;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease 0s;
}

.action-button + .action-button {
  margin-left: 24px;
}

.action-button__default {
  color: white;
  background-color: rgb(237, 28, 36);
  border-color: rgb(237, 28, 36);
}

.action-button:hover {
  border: 1px solid rgb(209, 209, 209, 1);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.12);
}

.action-button__default:hover {
  background-color: rgb(255, 70, 76);
}

.action-button:focus {
  outline: none;
}

.action-button--clicked {
  background-color: rgb(209, 209, 209, 0.5);
}

.action-button--selected {
  background-color: #B4B4B4;
  color: white;
  text-transform: uppercase;
}

.action-button:disabled {
  background-color: rgb(147, 147, 147);
  border: 1px solid rgb(147, 147, 147);
  cursor: default;
}

.action-button:disabled:hover {
  box-shadow: none;
}
