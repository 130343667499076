.query-page {
  width: 100%;
  /*display: grid;*/
  /*grid-template-columns: repeat(12, 1fr);*/
}

.query-page-header {
    display: flex;
    flex-direction: column;
}

.query-page__title {
  grid-column: 3/11;
  padding: 2em;
  border-radius: 4px;
  border: 1px solid rgb(209, 209, 209, 0.75);
  margin-bottom: 2em;
}
