.checkbox {
    padding-left: 12px;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

.checkbox-input {
    margin-right: 8px;
}

.checkbox--checked {
    color: #3A3A3A;
    font-weight: 600;
}

.checkbox--disabled {
    opacity: 0.5;
    cursor: not-allowed;
}