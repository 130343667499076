html,
body {
  font-size: 12px;
}

body {
  font-family: 'Fira sans', sans-serif;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app__content {
  flex: 1;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 1em;
}
