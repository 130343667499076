.collapse {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  margin: 0;
}

.collapse button {
  background-color: #505050;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin: 0;
}

.collapse p {
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  width: 100%;
  margin: 0;
}

.chevron-icon__right {
  transition: transform 150ms ease-in-out;
  height: 24px;
  width: 24px;
  stroke-width: 1;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.chevron-icon__down {
  transition: transform 150ms ease-out;
  transform: rotate(90deg);
  height: 24px;
  width: 24px;
  stroke-width: 1;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.transition--enter__leave {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition--from__to {
  opacity: 0;
}

.transition--to__from {
  opacity: 100;
}