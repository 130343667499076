.tabs-container {
  grid-column: 3/11;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media(max-width: 1440px) {
  .tabs-container {
    grid-column: 2/12;
  }
}

@media(max-width: 600px) {
  .tabs-container {
    grid-column: 1/13;
  }

  .tabs__spacer {
    display: none;
  }
}

.tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tabs__spacer {
  width: 80%;
  border-bottom: 1px solid #A2A2A2;
}

.tab {
  width: 100%;
  padding: 8px 10px 8px 10px;
  border: none;
  border-bottom: 1px solid #A2A2A2;
  background-color: white;
  font-family: 'Fira sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #545454;
}

.tab:hover {
  background-color: rgb(245, 243, 243);
}

.tab--selected {
  border: 1px solid #A2A2A2;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #ED1C24;
}

.tab--disabled:hover {
  background-color: white;
}

.tab:focus {
  outline: 0;
}
