.expandable-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #AFCED1;
  border-radius: 6px 17px 17px 6px;
  margin-top: 12px;
}

.expandable-section--expanded {
  border-bottom-right-radius: 6px;
}

.expandable-section--inner {
  border: 0;
}

.expandable-section:nth-child(1) {
  margin-top: 0;
}

.expandable-section__header {
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  appearance: none;
  border: 0;
  background-color: transparent;
  text-align: left;
  border-radius: 6px 20px 20px 6px;
}

.expandable-section--inner .expandable-section__header {
  border-radius: 4px 15px 15px 4px;
  background-color: #F6F6F6;
}

/* .expandable-section__header:hover {
  background-color: rgba(175, 206, 209, 0.05);
} */

.expandable-section__header .title {
  margin-left: 12px;
  flex-grow: 1;
  font-weight: 700;
  font-size: 16px;
  color: #3A3A3A;
}

.expandable-section--inner .expandable-section__header .title {
  font-size: 14px;
}

.expandable-section__header .count {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ED1C24;
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expandable-section--inner .expandable-section__header .count {
  width: 20px;
  height: 20px;
  background-color: #4E4E4E;
}

.expandable-section__header svg {
  margin-left: 60px;
  margin-right: 4px;
  width: 26px;
  height: 26px;
}

.expandable-section--inner .expandable-section__header svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.expandable-section__content {
  display: none;
}

.expandable-section--expanded > .expandable-section__header .count {
  display: none;
}

.expandable-section--expanded > .expandable-section__header svg {
  transform: rotate(180deg);
}

.expandable-section--expanded > .expandable-section__content {
  display: block;
  padding: 20px 12px;
}