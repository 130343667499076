.tooltip {
  width: 20px;
  height: 20px;
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #959595;
}

.tooltip svg {
  width: 20px;
  height: 20px;
}

.tooltip:hover {
  color: #ed1c24;
}

.tooltip__content {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #363636;
  border: 0;
  border-radius: 12px;
  padding: 32px 40px;
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  text-transform: initial;
  width: 540px;
  max-width: max-content;
  pointer-events: none;
  z-index: 100;
}

@media (min-width: 360px) {
  .tooltip__content--bottom {
    transform: translateX(-75%) translateY(23px);
  }

  .tooltip__content--bottom:before {
    left: 75%;
    transform: translateX(calc(-50%));
  }
}

/** Box Positioning */
.tooltip__content--top {
  transform: translateX(-50%) translateY(calc(-100% - 26px));
}

.tooltip__content--topLeft {
  transform: translateX(-80px) translateY(calc(-100% - 26px));
}

.tooltip__content--topRight {
  transform: translateX(calc(-100% + 77px)) translateY(calc(-100% - 26px));
}

.tooltip__content--bottom {
  transform: translateX(-93%) translateY(23px);
}

/* Responsive vertical position of the box */
@media (min-width: 360px) {
  .tooltip__content--bottom {
    transform: translateX(-90%) translateY(23px);
  }
}

@media (min-width: 400px) {
  .tooltip__content {
    width: 400px;
    max-width: max-content;
  }
  .tooltip__content--bottom {
    transform: translateX(-75%) translateY(23px);
  }
}

@media (min-width: 540px) {
  .tooltip__content--bottom {
    transform: translateX(-65%) translateY(23px);
  }
}

@media (min-width: 600px) {
  .tooltip__content {
    width: 460px;
    max-width: max-content;
  }
  .tooltip__content--bottom {
    transform: translateX(-50%) translateY(23px);
  }
}

@media (min-width: 768px) {
  .tooltip__content {
    width: 400px;
    max-width: max-content;
  }
  .tooltip__content--bottom {
    transform: translateX(-66%) translateY(23px);
  }
}

@media (min-width: 1024px) {
  .tooltip__content {
    width: 540px;
    max-width: max-content;
  }
  .tooltip__content--bottom {
    transform: translateX(-50%) translateY(23px);
  }
}

.tooltip__content--bottomLeft {
  transform: translateX(-80px) translateY(23px);
}

.tooltip__content--bottomRight {
  transform: translateX(calc(-100% + 77px)) translateY(23px);
}

/** Arrow positioning */
.tooltip__content:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 24px solid transparent;
  border-left-width: 8px;
  border-right-width: 8px;
  border-bottom-color: #363636;
}

.tooltip__content--top:before,
.tooltip__content--bottom:before {
  left: 93%;
  transform: translateX(calc(-50%));
}

@media (min-width: 360px) {
  .tooltip__content--bottom:before {
    left: 90%;
  }
}

@media (min-width: 400px) {
  .tooltip__content--bottom:before {
    left: 75%;
  }
}

@media (min-width: 540px) {
  .tooltip__content--bottom:before {
    left: 65%;
  }
}

@media (min-width: 600px) {
  .tooltip__content--bottom:before {
    left: 50%;
  }
}

@media (min-width: 768px) {
  .tooltip__content--bottom:before {
    left: 66%;
  }
}

@media (min-width: 1024px) {
  .tooltip__content--bottom:before {
    left: 50%;
  }
}

.tooltip__content--top:before,
.tooltip__content--topLeft:before,
.tooltip__content--topRight:before {
  top: 100%;
  border-bottom-color: transparent;
  border-top-color: #363636;
}

.tooltip__content--topLeft:before {
  left: 73px;
}

.tooltip__content--topRight:before {
  right: 68px;
}

.tooltip__content--bottom:before,
.tooltip__content--bottomLeft:before,
.tooltip__content--bottomRight:before {
  bottom: 100%;
}

.tooltip__content--bottomLeft:before {
  left: 73px;
}

.tooltip__content--bottomRight:before {
  right: 68px;
}

.tooltip__content h1,
.tooltip__content h2,
.tooltip__content h3,
.tooltip__content h4,
.tooltip__content h5,
.tooltip__content h6,
.tooltip__content strong {
  font-size: 18px;
  line-height: 26px;
  margin: 0 0 24px;
}

.tooltip__content p {
  margin: 0;
}
