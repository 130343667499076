body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans', sans-serif;
}

.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.preventModalScrolling {
  touch-action: none;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  overscroll-behavior: none;
}

@media screen and (min-width: 767px) { /* Tablet & Desktop */
  .container {
    flex-direction: row;
    max-width: calc(100vw - 2rem);
  }
}