.calendar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(49, 49, 49, 0.62);
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar {
  box-sizing: border-box;
  width: 500px;
  margin-left: 16px;
  opacity: 1;
  transition: opacity 1s ease-out;
  background-color: white;
  border: 1px solid gray;
  border-radius: 22px;
  overflow: hidden;
}

.calendar-header {
  position: relative;
  background-color: #ED1C24;
  padding: 20px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: white;
}

.calendar-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: white;
  cursor: pointer;
}

.calendar-close svg {
  width: 30px;
  height: 30px;
}

.calendar-content {
  padding: 16px 24px;
}

.calendar--loading {
  animation: pulse 0.5s ease-in-out alternate infinite;
}

@keyframes pulse {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0.7;
  }
}

.calendar__month {
  display: flex;
  flex-direction: column;
}

.calendar__month__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 16px;
}

.calendar__month__header button {
  appearance: none;
  border: 0;
  padding: 8px;
  background-color: transparent;
  cursor: pointer;
}

.calendar__month__header button img {
  width: 40px;
  height: 40px;
}


.calendar__month__name {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #8A8A8A;
  margin: 0 20px;
}

.calendar__month__name::before,
.calendar__month__name::after {
  content: '';
  width: 32px;
  height: 1px;
  position: absolute;
  top: 50%;
  background-color: #ED1C24;
}

.calendar__month__name::before {
  right: calc(100% + 16px);
}

.calendar__month__name::after {
  left: calc(100% + 16px);
}

.calendar__week {
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
}

.calendar__week-header {
  display: flex;
  justify-content: space-between;
}
.calendar__week-header p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: calc(100% / 7);
}

.calendar__day {
  width: calc(100% / 7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar__day button {
  border: 0;
  appearance: none;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
  border-radius: 24px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}

.calendar__day--has-deadline:hover button {
  background-color: rgb(240, 240, 240);
}

.calendar__day--not-same-month button {
  color: rgb(196, 196, 196);
}

.calendar__day--has-deadline button {
  color: red;
  cursor: pointer;
}

.calendar__day--today button {
  box-shadow: 0 0 0 2px rgb(240, 240, 240);
}

.mini-calendar {
  position: relative;
}

.mini-calendar__wrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mini-calendar img {
  width: 90px;
}

.mini-calendar button {
  border: 0;
  appearance: none;
  background-color: transparent;
  font-size: 38px;
  position: absolute;
  top: calc(50% - 8px);
  left: 50%;
  transform: translate(-50%, calc(-50% + 23px));
  width: 90px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calendar__view-date__header {
  display: flex;
  align-items: center;
}

.calendar__view-date__header button {
  box-sizing: border-box;
  padding: 0;
  min-width: 80px;
  appearance: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.calendar__view-date__header button img {
  width: 40px;
}

.calendar__view-date__header p {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: #9E9E9E;
}

.calendar__view-date__content {
  padding: 20px 0 20px 0;
}

.calendar__view-date__deadline-date {
  padding: 20px 0 20px 80px;
  border-top: 1px solid #BEBEBE;
}

.calendar__view-date__deadline-date:nth-child(1) {
  border-top: none;
}

.calendar__view-date__deadline-date p {
  margin: 0;
}

@media screen and (max-width: 600px) {
  .calendar {
    margin-left: 20px;
    margin-right: 20px;
  }
}