.settings-card {
  margin-top: 32px;
}

.settings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settings-header h3 {
  margin: 0;
}

.settings-list {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.setting-item {
  margin-top: 16px;
}

.setting-item:nth-child(1) {
  margin-top: 0;
}

.setting-item select {
  padding: 8px 4px;
  width: 100%;
}

.setting-label {
  padding: 2px 0;
}

.setting-description {
  margin-top: 4px;
  font-size: 14px;
  color: gray;
}

.setting-content {
  margin-top: 4px;
}

.setting-option-group {
  display: flex;
}

.setting-option {
  margin-left: 8px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  border: 1px solid #E1E1E1;
  padding: 8px;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.setting-option:hover {
  background-color: #e1e1e182;
}

.setting-option.selected {}

.setting-option.disabled {
  cursor: wait;
}

.setting-option.disabled:hover {
  background-color: transparent;
}

.setting-option:nth-child(1) {
  margin-left: 0;
}

.setting-option input {
  margin: 0;
}

.setting-option p {
  margin: 0 0 0 8px;
}