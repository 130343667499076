header {
  position: relative;
  background-color: #fff;
  padding: 18px;
  border-bottom: 1px solid #d0d0d0;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  margin-left: 0;
  height: 36px;
  max-width: 200px;
}

header nav {
  display: flex;
  align-items: center;
}

header nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  color: #707070;
  font-size: 13px;
  font-weight: 600;
  padding: 0 9px;
  text-decoration: none;
  border-left: 1px solid #c5c5c5;
  transition: color 0.1s ease-out;
}

header nav a:hover {
  color: #ed1c24 !important;
}

header nav a.active {
  color: #ed1c24 !important;
}

header nav a:nth-child(1) {
  border-left: 0;
}

header nav a.header__logout {
  border-left: 0;
  color: #b2b2b2;
}

header nav button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  color: #707070;
  font-size: 13px;
  font-weight: 600;
  padding: 0 9px;
  text-decoration: none;
  border-width: 0;
  z-index: 10;
  background-color: transparent;
  transition: color 0.1s ease-out;
  cursor: pointer;
}

.background-button {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  opacity: 0.02;
  min-height: 100vh;  
  cursor: pointer;
}

header nav button:hover {
  color: #ed1c24 !important;
}

.menu__layout {
  position: relative;
  display: flex;
  transition-duration: 250ms;
}

.menu__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  color: #707070;
  font-weight: 600;
  padding: 0 18px;
  text-decoration: none;
  border-left: 1px solid #c5c5c5;
  transition: color 0.1s ease-out;
  font-family: 'Fira Sans', sans-serif;
  font-size: 20px;
}

.menu__button:hover {
  cursor: pointer;
  color: #ed1c24 !important;
}

.help__menu {
  display: flex;
  z-index: 20;
  position: absolute;
  flex-direction: column;
  justify-items: center;
  gap: 3px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  height: 30px;
  padding: 5px 0 2px 0;
  top: 55px;
  left: -1px;
  transition-duration: 250ms;
}

.help__menu__tooltip-arrow {
  position: absolute;
  z-index: 20;
  content: '';
  width: 0;
  height: 0;
  border: 24px solid transparent;
  border-left-width: 8px;
  border-right-width: 8px;
  border-bottom-color: #4d4d4d;
  top: 14px;
  left: 45%;
}

header nav a.menu__url {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 10px;
  font-weight: 600;
  text-decoration: none;
  border-left: 0;
  transition: color 0.1s ease-out;
}

.header__profile {
  padding-right: 16px;
  margin-right: 0;
}

.header__profile img {
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  border: 1px solid #707070;
}

.header__profile:hover img {
  border: 1px solid #ed1c24;
}

.header__logout {
  padding-left: 16px;
  font-size: 13px;
}

.user_icon {
  height: 36px;
  width: 36px;
}

.trial_warning {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0 0 0;
  gap: 6px;
  color: #707070;
  margin: 0;
}

.trial_warning span{
  font-size: 16px;
  color: #ed1c24;
}

.bell_icon {
  height: 18px;
  width: 18px;
}

/* Mobile view at 375px min */
@media (min-width: 375px) {
  .header__logo {
    margin-left: 4px;
    height: 36px;
  }

  .header__profile {
    padding: 0 10px;
  }

  .header__profile img {
    width: 28px;
    height: 28px;
  }

  header nav a {
    height: 48px;
    font-size: 14px;
    padding: 0 10px;
  }

  header nav button {
    height: 48px;
    font-size: 14px;
    padding: 0 10px;
  }

  .help__menu {
    gap: 3px;
    height: 40px;
    padding: 5px 6px 2px 6px;
    top: 53px;
    left: -5px;
  }

  header nav a.menu__url {
    height: 12px;
    font-size: 12px;
    padding: 2px;
    border-left: 0;
  }

  .header__logout {
    padding: 0 2px;
    font-size: 13px;
  }

  .trial_warning {
    justify-content: center;
    padding-right: 0;
  }

  .bell_icon {
    height: 18px;
    width: 18px;
  }
}

/* Mobile view at 600px max */
@media (min-width: 600px) {
  .header__logo {
    height: 42px;
  }

  .header__profile {
    padding: 0 10px;
  }

  .header__profile {
    padding-right: 24px;
    padding-left: 24px;
  }

  .header__profile img {
    width: 28px;
    height: 28px;
  }

  header nav a {
    height: 48px;
    font-size: 16px;
    padding: 0 10px;
  }

  header nav button {
    height: 48px;
    font-size: 16px;
    padding: 0 10px;
  }
  
  .help__menu {
    gap: 3px;
    height: 40px;
    padding: 5px 6px 2px 6px;
    top: 55px;
    left: -5px;
  }

  header nav a.menu__url {
    height: 12px;
    font-size: 12px;
    padding: 2px;
    border-left: 0;
  }

  .header__logout {
    padding: 0 2px;
    font-size: 16px;
  }

  .trial_warning {
    justify-content: end;
    padding-right: 45px;
  }

  .bell_icon {
    height: 20px;
    width: 20px;
  }
}

/* Mobile view at 768px max */
@media (min-width: 768px) {
  .header__logo {
    height: 48px;
  }

  .header__profile {
    padding: 0 12px;
  }

  .header__profile {
    padding-right: 28px;
    padding-left: 28px;
  }

  .header__profile img {
    width: 32px;
    height: 32px;
  }

  header nav a {
    height: 48px;
    font-size: 18px;
    padding: 0 16px;
  }

  header nav button {
    height: 48px;
    font-size: 18px;
    padding: 0 16px;
  }

  .help__menu {
    gap: 6px;
    height: 45px;
    padding: 7px 7px 4px 7px;
    top: 55px;
    left: -5px;
  }

  header nav a.menu__url {
    height: 12px;
    font-size: 15px;
    padding: 2px;
    border-left: 0;
  }
  
  .header__logout {
    padding: 0 4px;
    font-size: 18px;
  }
}

/* desktop view at 1024px minimum */
@media (min-width: 1024px) {
  header {
    padding: 24px 32px;
  }

  .header__profile {
    padding-right: 32px;
    padding-left: 32px;
  }

  .header__profile img {
    width: 36px;
    height: 36px;
  }

  .header__logout {
    padding: 0 6px;
  }

  header nav a {
    height: 52px;
    font-size: 20px;
    padding: 0 24px;
  }

  .help__menu {
    gap: 12px;
    height: 50px;
    padding: 7px 9px 10px 9px;
    top: 55px;
    left: -5px;
  }

  header nav a.menu__url {
    height: 12px;
    font-size: 19px;
    padding: 2px;
    border-left: 0;
  }

  header nav button {
    height: 52px;
    font-size: 20px;
    padding: 0 24px;
  }
}

/* desktop view at 1440px minimum */
@media (min-width: 1440px) {
  header {
    padding: 24px 32px;
  }

  .header__logo {
    height: 48px;
  }

  .header__profile {
    padding-right: 36px;
    padding-left: 36px;
  }

  .header__profile img {
    width: 38px;
    height: 38px;
  }

  .header__logout {
    padding: 0 8px;
  }

  header nav a {
    height: 56px;
    font-size: 22px;
    padding: 0 32px;
  }

  .help__menu {
    gap: 12px;
    height: 50px;
    padding: 7px 9px 10px 9px;
    top: 55px;
    left: -5px;
  }

  header nav a.menu__url {
    height: 12px;
    font-size: 19px;
    padding: 2px;
    border-left: 0;
  }

  header nav button {
    height: 56px;
    font-size: 22px;
    padding: 0 32px;
  }
}
