.result-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.result-label__value {
  display: flex;
  align-items: center;
  max-width: 400px;
}

.result-label strong {
  margin: 0 8px;
  white-space: nowrap;
}

.result-label__force-row {
  width: 100%;
  margin-top: 8px;
}

.result-label__force-row:nth-child(1) {
  margin-top: 0;
}