.upload-file-container {
    width: 100%;
}
.file-selection-container {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: black;
    border: none;
    gap: 10px
}

.file-container {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 0 5px;
}

.selected-file-container {
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

svg {
  height: 130%;
  fill: red;
  background-color: rgba(70, 66, 66, 0.103);
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.205);
}

.show-file-container {
    cursor: pointer;
}
