.topic {
  box-sizing: border-box;
  position: fixed;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 80px);
  max-width: 1700px;
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  z-index: 10;
  background-color: white;
  border: 1px solid #ABABAB;
  border-radius: 6px;
  padding: 24px 16px 0 24px;
}

.topic h2 {
  font-size: 21px;
  font-weight: 600;
  color: #545454;
  margin-top: 0;
  margin-right: 48px;
}

.topic a {
  color: rgb(237, 28, 36);
}

.topic .close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #707070;
  border: 0;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.2s ease-out;
}

.topic .close svg {
  width: 32px;
  height: 32px;
}

.topic .close:hover {
  color: black;
}

.topic__content {
  overflow-y: auto;
}

.topic__content-wrapper {
  display: flex;
}

.topic table td {
  padding: 2px;
}

.topic__info .ms-Button {
  margin: 0.25em;
}
.topic__info > div {
  margin: 1em 1em 2em;
}

.topic__info > div:nth-last-child(1) {
  margin-bottom: 0;
}

.topic__info__basic h3 {
  margin-top: 0;
}

.topic__info .topic-info-drop {
  color: white;
  text-decoration: none;
}

.topic__info .topic-info-drop i {
  position: relative;
  top: 3px;
}

.topic__description {
  width: 100%;
  padding: 0 40px 0 0;
}

.topic__description table {
  border: 2px solid #DEDEDE;
  border-collapse: separate;
  border-radius: 29px;
  border-spacing: 0px;
}

.topic__description table th,
.topic__description table td {
  border-top: 2px solid #DEDEDE;
  border-left: 2px solid #DEDEDE;
  padding: 12px 20px;
}

.topic__description table tbody:nth-child(1) tr:nth-child(1) th,
.topic__description table tbody:nth-child(1) tr:nth-child(1) td {
  border-top: 0;
}

.topic__description table th:nth-child(1),
.topic__description table td:nth-child(1) {
  border-left: 0;
}

.topic__description table th {
  font-size: 20px;
  font-weight: 700;
  color: #808080;
}

.topic__description table td {
  font-size: 18px;
  line-height: 24px;
  color: #707070;
}


.topic__description h2 {
  margin-top: 0;
}
.topic__description p {
  line-height: 1.4em;
}
.topic__description ul {
  line-height: 1.4em;
}

.topic__description ul li {
  margin-bottom: 0.5rem;
}
.topic__description .topicdescriptionkind {
  font-weight: bold;
  font-size: 1.1em;
}



.topic__description .ms-CollapsibleSection {
  margin-bottom: 2em;
}

.topic__description .ms-CollapsibleSection button {
  height: 3em;
  line-height: 3em;
}

.topic__description .ms-CollapsibleSection button span {
  display: block;
  font-size: 1.5em;

  font-weight: bold;
}
.ms-Panel-content {
  overflow-wrap: anywhere;
}

.topic-information {
  line-height: 1.5em;
  list-style: none;
  padding-left: 0;
}

.topic-information > li {
  margin-bottom: 1rem;
}

.topic-information strong {
  display: block;
}

.topic__additional-data {
  margin-top: 1rem;
}

.share-call {
  margin-top: 20px;
  position: relative;
  border: 3px solid #E1E1E1;
  border-radius: 45px;
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
}

.share-call svg {
  width: 16px;
}

.share-call strong {
  color: #7D7D7D;
  font-size: 14px;
  margin-left: 10px;
}

.share-methods {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateX(20px) translateY(-50%);
  display: flex;
  padding: 8px;
  background-color: white;
  border: 3px solid #E1E1E1;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: slideIn 0.2s ease-out forwards;
}

.content-dialog .share-methods a {
  color: #ed1c24;
}

.share-methods:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border: 20px solid transparent;
  border-top-width: 8px;
  border-bottom-width: 8px;
  border-right-color: #E1E1E1;
}

.share-methods:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateX(8px) translateY(-50%);
  border: 20px solid transparent;
  border-top-width: 8px;
  border-bottom-width: 8px;
  border-right-color: white;
}

.share-methods a {
  color: #ed1c24;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  margin-top: 8px;
  padding: 8px;
}

.share-methods a:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.share-methods a:nth-child(1) {
  margin-top: 0;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(10px) translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateX(20px) translateY(-50%);
  }
}

.highlight-legend {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
}

.highlight-legend:nth-child(1) {
  margin-top: 0;
}

.highlight-legend:before {
  content: '';
  margin-top: 4px;
  width: 26px;
  min-width: 26px;
  height: 12px;
  border-radius: 4px;
  background-color: white;
}

.highlight-legend--blue:before { background-color: #80bfff; }
.highlight-legend--green:before { background-color: #90EE90; }
.highlight-legend--yellow:before { background-color: #FDEB7A; }
.highlight-legend--orange:before { background-color: #FFB380; }

.highlight-legend p {
  margin: 0 0 0 10px;
  line-height: 1.2em;
}

.ms-CollapsibleSection button {
  cursor: pointer;
}

.ms-CollapsibleSection button:hover {
  background-color: transparent;
}

.blur-text {
  text-shadow: 0 0 5px rgba(0,0,0,5);
  color:#c1c1c1;
}