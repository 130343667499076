.topicdescriptionkind {
  font-weight: bold;
  width: 100%;
}

.dropdown__list-custom {
  width: 200px;
}

.dropdown__item-custom {
  text-transform: initial;
}
