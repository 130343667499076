.license-agreement .actions {
    display: flex;
    justify-content: center;
}

.license-agreement .actions .check-agreement {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.license-agreement .actions .check-agreement span{
   padding-left: 0.5em;
    padding-right: 1em;
}