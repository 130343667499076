.modal__window {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  position: relative;
  width: 90%;
  max-width: 400px;
  /* max-height: 400px; */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3em;
}

.modal__close {
  position: absolute;
  top: 1em;
  right: 1em;
  border: none;
  padding: 0;
  cursor: pointer;
}

.modal__actions {
  position: absolute;
  right: 1em;
  bottom: 1em;
}

@media screen and (max-width: 550px) {
  .modal__container {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 390px) {
  .modal__content {
    margin-bottom: 10px;
  }
}
