.search-input {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    grid-column: 3/11;
}

@media(max-width: 1440px) {
  .search-input {
    grid-column: 2/12;
  }
}

@media(max-width: 600px) {
  .search-input {
    grid-column: 1/13;
  }
}

.search-input .search-terms {
    margin-bottom: 1em;
}

.search-input .submit-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

}

.search-input .button-group {
  display: flex;
  justify-content: space-between;
}

.search-input .button-group button {
  margin-left: 5px;
}

.search-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 35px;
}

.search-field label {
  font-size: 20px;
  font-weight: 600;
  color: #545454;
  display: flex;
  align-items: center;
}

.search-field label button {
  margin-left: 16px;
}

.search-field textarea {
  box-sizing: border-box;
  font-size: 16px;
  color: #808080;
  padding: 16px;
  margin-top: 16px;
  width: 100%;
  border: 1px solid #AAAAAA;
  border-radius: 3px;
  height: 220px;
  transition: height 0.3s ease-out;
}

.search-field textarea.shrink {
  height: 140px;
}
